<template>
  <div>
    <b-card v-if="Object.keys(remap).length > 0 && remap.enabled">
      <template #header>
        <h4 class="d-flex">
          <span class="flex-grow-1 my-auto">
            <b-btn style="margin-left:-1em;margin-right:0em;" variant="ghost-danger" @click="removeRemapping(remap)"><fluency-icon type="delete"/></b-btn>
            Blueprint Tweak for <template  v-if="selectedBlueprint && selectedBlueprint.text">{{selectedBlueprint.text}}</template><template v-else>All Blueprints</template>
          </span>
          <b-btn :variant="(remap.dirty)?'ghost-warning':'ghost-success'" @click="save()"><fluency-icon type="save" /> Save</b-btn>
        </h4>
      </template>
      <div class="d-flex flex-wrap align-items-center">

        <mad-lib-select class="mr-4"
                        :value="remap.clone"
                        :options="cloneOptions"
                        @input="remap.clone = $event; dirty()" />
        <span class="mr-4 py-3">Ad Groups That</span>

        <div v-for="(rule, i) in remap.contentRemappingRules.filter(map => map.enabled)" :key="`rule${i}`" class="d-flex align-items-center">
          <span v-if="i > 0" class="mr-4">or</span>
          <mad-lib-select :value="rule.matchType"
                          class="mr-4"
                          :options="matchTypes"
                          @input="rule.matchType = $event; dirty()" />
          <basic-mad-lib-input v-model="rule.adGroupMatcher" class="mr-2" :disabled="rule.matchType === 'ALL'" @change="dirty()"></basic-mad-lib-input>
          <b-btn class="d-flex align-items-center p-2" v-p-tooltip.top="'Add Another Match Rule'" variant="ghost-primary" @click="addMatchRule()"><fluency-icon type="add"/></b-btn>
          <b-btn v-if="remap.contentRemappingRules.filter(map => map.enabled).length > 1" class="ml-2 d-flex align-items-center p-2" variant="ghost-danger" @click="rule.enabled = false"><fluency-icon type="delete"/></b-btn>
        </div>

        <span class="mx-4 py-3">from</span>

        <basic-mad-lib-input class="mr-4"
                             :value="remap.sourceCampaignName"
                             placeholder="Select a Campaign"
                             :type-ahead-options="campaigns"
                             require-option
                             @input="remap.sourceCampaignName = $event; dirty()" />

        <span class="mr-4 py-3">to</span>

        <basic-mad-lib-input :value="remap.destinationCampaignName"
                             placeholder="Select, or Create a New Campaign"
                             :type-ahead-options="campaigns"
                             @input="remap.destinationCampaignName = $event; dirty()" />

      </div>
    </b-card>
  </div>
</template>

<script>
import MadLibSelect from 'core-ui/components/inputs/madLibSelect'
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'
import _cloneDeep from 'lodash.clonedeep'

export default {
  name: 'blueprintRemapping',
  components: { BasicMadLibInput, MadLibSelect },
  props: ['value', 'blueprints', 'campaigns'],
  data () {
    return {
      matchTypes: [
        { text: 'Match', value: 'MATCHES' },
        { text: 'Contain', value: 'CONTAINS' },
        { text: 'Start with', value: 'STARTS_WITH' },
        { text: 'End with', value: 'ENDS_WITH' },
        { text: 'All', value: 'ALL' }
      ],
      remap: {},
      cloneOptions: [{ text: 'Copy', value: true }, { text: 'Move', value: false }]
    }
  },
  watch: {
    value: {
      handler () {
        this.$setCompat(this, 'remap', _cloneDeep(this.value))
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    selectedBlueprint () {
      return this.blueprints.filter(bloop => bloop.value === this.remap.contentTemplateId)[0]
    }
  },
  methods: {
    async save (areRemoving) {
      if (this.remap.enabled && this.remap.sourceCampaignName === this.remap.destinationCampaignName) {
        this.$alert('Please resolve issues highlighted in red before saving.')
        return
      }
      if (!this.remap.enabled && !this.remap.contentRemappingId) {
        return // item is being deleted before it was ever saved
      }
      delete this.remap.dirty
      const response = await this.$res.set.accountBlueprintRemapping(this.remap)
      if (response) {
        this.$setCompat(this, 'remap', response)
        this.$toast((areRemoving) ? 'Remapping Removed' : 'Remapping saved', 'success')
      }
    },
    async removeRemapping () {
      if (await this.$confirm('Are you sure you want to remove this remapping?')) {
        this.remap.enabled = false
        this.save(true)
      }
    },
    addMatchRule () {
      this.remap.contentRemappingRules.push({
        matchType: 'MATCHES',
        adGroupMatcher: '',
        enabled: true
      })
      this.dirty()
    },
    dirty () {
      this.$setCompat(this.remap, 'dirty', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.match-select.custom-select {
  background-color: $fluency-lightergray;
}
</style>
